<template>
  <Border v-if="errors.length > 0">
    <ul>
      <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
    </ul>
  </Border>
</template>

<script>
import Border from "@/components/Border.vue";
export default {
  name: "Error",
  components: {
    Border,
  },
  props: {
    errors: Array,
  },
  watch: {
    errors: function () {
      if (this.errors && this.errors.length > 0) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 5px;
  margin: 0px;
  color: red;
}
li {
  padding: 0px;
  margin: 0px;
}
.error {
  font-weight: bold;
  color: red;
}
</style>
