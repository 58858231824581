<template>
  <transition name="modal">
    <div v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-content">
              <div class="modal-header">
                <div>
                  <!-- <img src="../assets/q.png" class="imgIcon smaller" /> -->
                  <img src="../assets/delete.png" class="imgIcon smaller right" @click="$emit('closeHelpModal')"/>
                  <h3>
                    <slot name="header"> {{ helpHeader }} </slot>
                  </h3>
                </div>
              </div>
              <div class="modal-body">
                <slot name="body"> {{ helpText }} </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="$emit('closeHelpModal')">
                    close
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "HelpModal",
  props: {
    showModal: Boolean,
    helpHeader: String,
    helpText: String,
  },
  data: function () {
    return {

    };
  },
  methods: {
    close() {
      this.closeModal = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  /* overflow: auto; */

}

.modal-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-container {
  width: 95%;
  max-width: 650px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}

.modal-content {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  text-decoration: none;
  background-color: rgba(251, 251, 251, 0.9);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 6px;
  border: solid 1px silver;

  text-align: left;
  padding: 20px 30px 10px;
  overflow: auto;
  height: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: rgb(253, 75, 5);
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  padding: auto;
  margin: 10px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
