<template>
  <div>
    <Loading v-bind:showLoadScreen="screenLoading" />
    <Error v-bind:errors="errors" />
    <Border>
      <img src="../assets/left.png" class="imgIcon smaller" @click="goBack()" />
      <img src="../assets/q.png" class="imgIcon right smaller" @click="rewardHelp()" />

      <HelpModal v-bind:showModal="showRewardHelp" helpHeader="" helpText="" @closeHelpModal="showRewardHelp = false">
        <div slot="header">What are rewards</div>
        <div slot="body">Rewards are....</div>
      </HelpModal>

      <div class="lineItem item3" v-if="showCreateRewardButton">Create a reward</div>
      <a id="top"></a>
      <h3 v-if="showUpdateRewardButton">Update/Delete a reward</h3>
      <div class="centerOfRow">
        <label>Name</label>&nbsp;
        <input class="rcornersInput" v-model="reward.rewardName" placeholder="Reward name" />
      </div>
      <div class="centerOfRow">
        <label>Goal</label>
        <input class="rcornersInput" v-model="reward.redeemAmount" placeholder="Total events needed to unlock reward"
          type="number" min="0" />
      </div>
      <div class="centerOfRow">
        <label>Reward</label>
        <input class="rcornersInput" v-model="reward.actualReward" placeholder="Reward if goal is reached" />
      </div>
      <div class="centerOfRow">
        <label>Expire</label>
        <input class="rcornersInput" v-model="reward.voucherExpireDays" placeholder="Redeem rewards in x days" />
      </div>

      <div class="centerOfRow">
        <label>Start</label>
        <div style="width: 83%">
          <datepicker input-class="rcornersInput dateWidth" calendar-class="innerCalendar" format="dd MMM yyyy"
            v-model="reward.startDate" name="reward.startDate" placeholder="Start date"></datepicker>
        </div>
      </div>
      <div style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
        ">
        <label>End</label>
        <div style="width: 83%">
          <datepicker input-class="rcornersInput" calendar-class="innerCalendar" format="dd MMM yyyy"
            v-model="reward.endDate" name="reward.endDate" placeholder="End date"></datepicker>
        </div>
      </div>

      <div v-if="reward.status.length > 0" class="centerOfRow">
        <label>Status</label>
        <input class="rcornersInput greyedOut" v-model="reward.status" placeholder="Reward status" readonly />
      </div>

      <div class="rightButton">
        <button v-if="showCreateRewardButton" @click="createReward" class="center">
          Create reward
        </button>
      </div>
      <div class="centerOfRow">
        <button v-if="showUpdateRewardButton" @click="deleteSelectedReward">
          Delete
        </button>
        <button v-if="showUpdateRewardButton" @click="updateReward">
          Update
        </button>
      </div>
      <div class="centerOfRow">
        <h3 v-if="showConfirmDeleteButton">Are you sure?</h3>
      </div>
      <div class="centerOfRow">
        <button v-if="showConfirmDeleteButton" @click="deleteRewardConfirmed">
          yes
        </button>
        <button v-if="showConfirmDeleteButton" @click="deleteRewardCancelled">
          no
        </button>
      </div>
    </Border>

    <Border v-bind:showLoading="loadRewardData">
      <div class="lineItem item3">Existing rewards</div>
      <div v-if="rewards.length > 0">
        <div v-for="reward in rewards" v-bind:key="reward.rewardId" class="item">
          <img src="../assets/edit4.png" class="imgIcon right smaller" @click="loadReward(reward.rewardId)" />
          <div class="lineItemHeader">~ {{ reward.rewardName }} reward ~</div>
          <div class="lineItem">
            {{ reward.actualReward }} after {{ reward.redeemAmount }} scanned events
          </div>
          <div class="lineItem">
            Valid from {{ reward.startDate }} to {{ reward.endDate }}
          </div>
          <div class="lineItem">Status: {{ reward.status }}</div>
        </div>
      </div>
      <div v-if="rewards && rewards.length < 0 && loadRewardData">
        Loading rewards...
      </div>
      <div v-if="rewards && rewards.length < 0 && !loadRewardData">
        No rewards found.
      </div>
    </Border>
  </div>
</template>

<script>
import Border from "@/components/Border.vue";
import HelpModal from "@/components/HelpModal.vue";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";
import Datepicker from "vuejs-datepicker";
import Comms from "../shared";
export default {
  name: "Admin",
  components: {
    Border,
    Error,
    Loading,
    Datepicker,
    HelpModal,
  },
  data: function () {
    return {
      errors: [],
      screenLoading: false,
      loadRewardData: false,
      showCreateRewardButton: true,
      showConfirmDeleteButton: false,
      showUpdateRewardButton: false,
      showRewardHelp: false,
      backToHome: false,
      rewards: [],
      reward: {
        rewardId: "",
        vendorId: "",
        vendorName: "",
        rewardName: "",
        redeemAmount: "",
        startDate: "",
        endDate: "",
        voucherExpireDays: "",
        actualReward: "",
        status: "",
      },
    };
  },
  methods: {
    createReward() {
      this.errors = [];
      if (this.validReward()) {
        this.screenLoading = true;
        this.reward.vendorId = this.$store.state.sytUser.userID;
        this.reward.vendorName = this.$store.state.sytUser.vendorName;
        if (this.reward.startDate instanceof Date) {
          this.reward.startDate = this.reward.startDate
            .toISOString()
            .substring(0, 10);
        }
        if (this.reward.endDate instanceof Date) {
          this.reward.endDate = this.reward.endDate
            .toISOString()
            .substring(0, 10);
        }
        Comms.sendToServer("POST", "addReward", this.reward, this.errors).then(
          (response) => {
            // console.log(response);
            if (response && response.status == 200) {
              // console.log("Setting rewardId to " + JSON.parse(response.data).rewardId);
              this.reward.rewardId = JSON.parse(response.data).rewardId;
              let rewardInList = Object.assign({}, this.reward);
              this.rewards.push(rewardInList);
              this.resetReward();
            }
            this.screenLoading = false;
          }
        );
      }
    },
    goBack() {
      if (this.backToHome) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "Admin" });
      }
    },
    rewardHelp() {
      this.showRewardHelp = true;
    },
    updateReward() {
      this.errors = [];
      if (this.validReward()) {
        this.screenLoading = true;
        if (this.reward.startDate instanceof Date) {
          this.reward.startDate = this.reward.startDate
            .toISOString()
            .substring(0, 10);
        }
        if (this.reward.endDate instanceof Date) {
          this.reward.endDate = this.reward.endDate
            .toISOString()
            .substring(0, 10);
        }
        Comms.sendToServer(
          "POST",
          "updateReward",
          this.reward,
          this.errors
        ).then((response) => {
          // console.log(response);
          if (response.status == 200) {
            // update the array with the new values
            let updatedReward = JSON.parse(response.data);
            // let updatedReward = Object.assign({}, this.reward);
            this.rewards[
              this.rewards.findIndex((x) => x.rewardId === this.reward.rewardId)
            ] = updatedReward;
            this.resetReward();
            this.showCreateRewardButton = true;
            this.showUpdateRewardButton = false;
            this.showConfirmDeleteButton = false;
          }
          this.screenLoading = false;
        });
      }
    },
    deleteRewardConfirmed() {
      this.errors = [];
      this.screenLoading = true;
      Comms.sendToServer("POST", "deleteReward", this.reward, this.errors).then(
        (response) => {
          // console.log(response);
          if (response.status == 204) {
            this.rewards.splice(
              this.rewards.findIndex(
                (x) => x.rewardId === this.reward.rewardId
              ),
              1
            );
            this.resetReward();
            this.showCreateRewardButton = true;
            this.showUpdateRewardButton = false;
            this.showConfirmDeleteButton = false;
          }
          this.screenLoading = false;
        }
      );
    },
    deleteRewardCancelled() {
      this.showCreateRewardButton = false;
      this.showUpdateRewardButton = true;
      this.showConfirmDeleteButton = false;
    },
    loadReward(rewardId) {
      document.location.hash = "";
      document.location.hash = "top";

      this.reward = Object.assign(
        {},
        this.rewards[this.rewards.findIndex((x) => x.rewardId === rewardId)]
      );
      this.errors = [];
      this.showCreateRewardButton = false;
      this.showUpdateRewardButton = true;
      this.showConfirmDeleteButton = false;
    },
    deleteSelectedReward() {
      this.showCreateRewardButton = false;
      this.showUpdateRewardButton = false;
      this.showConfirmDeleteButton = true;
    },
    resetReward() {
      this.reward.rewardId = "";
      this.reward.rewardName = "";
      this.reward.redeemAmount = "";
      this.reward.actualReward = "";
      this.reward.startDate = "";
      this.reward.endDate = "";
      this.reward.voucherExpireDays = "";
      this.reward.vendorName = "";
      this.reward.status = "";
      // this.errors = [];
    },
    validReward() {
      if (!this.reward.rewardName) {
        this.errors.push("Reward name required.");
      }
      if (!this.reward.redeemAmount) {
        this.errors.push("Reward amount required.");
      }
      if (!this.reward.actualReward) {
        this.errors.push("Reward required.");
      }
      if (!this.reward.startDate) {
        this.errors.push("Start date required.");
      }
      if (!this.reward.endDate) {
        this.errors.push("End date required.");
      }
      if (this.reward.startDate && this.reward.endDate) {
        // console.log(this.reward.startDate);
        // console.log(this.reward.endDate);
        let shortStartDate;
        let shortEndDate;
        if (this.reward.startDate.length > 10) {
          shortStartDate = this.reward.startDate.toISOString().substring(0, 10)
        } else {
          shortStartDate = this.reward.startDate;
        }
        if (this.reward.endDate.length > 10) {
          shortEndDate = this.reward.endDate.toISOString().substring(0, 10)
        } else {
          shortEndDate = this.reward.endDate;
        }
        if (new Date(shortStartDate) > new Date(shortEndDate)) {
          this.errors.push("Start date must be before end date");
        }
      }

      if (this.errors.length > 0) {
        return false;
      }
      return true;
    },
  },
  mounted: function () {
    this.screenLoading = true;
    if (!this.$store.state.authenticated) {
      this.$router.push({ name: "Home" });
    } else {
      this.loadRewardData = true;
      Comms.sendToServer(
        "POST",
        "getAllRewards",
        this.$store.state.sytUser,
        this.errors
      ).then((response) => {
        // console.log(response);
        if (response.status == 200) {
          this.rewards = JSON.parse(response.data);
        }
        this.loadRewardData = false;
        // now check if a reward was selected on the home screen
        if (
          this.$store.state.rewardToEdit &&
          this.$store.state.rewardToEdit.rewardId.length > 0
        ) {
          this.reward = this.$store.state.rewardToEdit;
          this.$delete(this.reward, "rewardSummary");
          this.showCreateRewardButton = false;
          this.showUpdateRewardButton = true;
          this.showConfirmDeleteButton = false;
          this.backToHome = true;
          //now clear the stored reward
          this.$store.commit("setRewardToEdit", "");
        } else {
          this.showCreateRewardButton = true;
          this.showUpdateRewardButton = false;
          this.showConfirmDeleteButton = false;
          this.backToHome = false;
        }
      });
    }
    this.screenLoading = false;
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  width: 70px;
  text-align: right;
  font-size: 1.3em;
}

.greyedOut {
  background-color: rgba(207, 205, 205, 0.8);
}

.dateWidth {
  width: 100%;
}
</style>